export default {
  namespaced: true,
  state: {
    introOfferSelectedStudio: 'Costa Mesa'
  },
  mutations: {
    setIntroOfferSelectedStudio: (state, val) => {
      state.introOfferSelectedStudio = val;
    },
  },
  actions: {
  },
};
