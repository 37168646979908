<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="1" class="pa-4">
          <v-col cols="12">
            <h2>Group Text System</h2>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <v-simple-table :headers="headers" :items="employees">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th v-for="header in headers" :key="header.text">
                      {{header.text}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item, index in employees"
                    :key="index"
                  >
                    <td>
                      <v-simple-checkbox v-ripple @click.prevent="updateEmployeeMetaNaughty(item.employeeMeta.id, item.employeeMeta.isNaughtyList, index)" :value="item.employeeMeta.isNaughtyList"></v-simple-checkbox>
                    </td>
                    <td>
                      <v-simple-checkbox v-ripple @click.prevent="updateEmployeeMetaEnabled(item.employeeMeta.id, item.employeeMeta.groupTextEnabled, index)" :value="item.employeeMeta.groupTextEnabled"></v-simple-checkbox>
                    </td>
                    <td>{{item.user.attributes.full_name}}</td>
                    <td>{{item.user.attributes.phone_number}}</td>
                    <td>
                      <v-simple-checkbox v-ripple @click.prevent="updateEmployeeMetaHotRa(item.employeeMeta.id, !item.employeeMeta.groupTextHotRa, index)" :value="item.employeeMeta.groupTextHotRa"></v-simple-checkbox>
                    </td>
                    <td>
                      <v-simple-checkbox v-ripple @click.prevent="updateEmployeeMetaVinyasa(item.employeeMeta.id, !item.employeeMeta.groupTextVinyasa, index)" :value="item.employeeMeta.groupTextVinyasa"></v-simple-checkbox>
                    </td>
                    <td>
                      <v-simple-checkbox v-ripple @click.prevent="updateEmployeeMetaYogalates(item.employeeMeta.id, !item.employeeMeta.groupTextYogalates, index)" :value="item.employeeMeta.groupTextYogalates"></v-simple-checkbox>
                    </td>
                    <td>
                      <v-simple-checkbox v-ripple @click.prevent="updateEmployeeMetaStrength(item.employeeMeta.id, !item.employeeMeta.groupTextStrength, index)" :value="item.employeeMeta.groupTextStrength"></v-simple-checkbox>
                    </td>
                    <td>
                      <v-simple-checkbox v-ripple @click.prevent="updateEmployeeMetaPrenatal(item.employeeMeta.id, !item.employeeMeta.groupTextPrenatal, index)" :value="item.employeeMeta.groupTextPrenatal"></v-simple-checkbox>
                    </td>
                    <td>
                      <v-simple-checkbox v-ripple @click.prevent="updateEmployeeMetaYin(item.employeeMeta.id, !item.employeeMeta.groupTextYin, index)" :value="item.employeeMeta.groupTextYin"></v-simple-checkbox>
                    </td>
                    <td>
                      <v-simple-checkbox v-ripple @click.prevent="updateEmployeeMetaBaby(item.employeeMeta.id, !item.employeeMeta.groupTextBaby, index)" :value="item.employeeMeta.groupTextBaby"></v-simple-checkbox>
                    </td>
                    <td>
                      <v-simple-checkbox v-ripple @click.prevent="updateEmployeeMetaMeditation(item.employeeMeta.id, !item.employeeMeta.groupTextMeditation, index)" :value="item.employeeMeta.groupTextMeditation"></v-simple-checkbox>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
// import find from 'lodash/find'

export default {
  name: 'EmployeesPage',

  data() {
    return {
      headers: [
        {
          text: 'No Show Alert',
          value: 'employeeMeta.isNaughtyList'
        },
        {
          text: 'Enabled',
          value: 'employeeMeta.groupTextEnabled',
        },
        {
          text: 'Name',
          value: 'user.attributes.full_name',
        },
        {
          text: 'Phone',
          value: 'user.attributes.phone_number'
        },
        {
          text: 'Hot Ra',
          value: 'employeeMeta.groupTextHotRa',
        },
        {
          text: 'Vinyasa',
          value: 'employeeMeta.groupTextVinyasa',
        },
        {
          text: 'Yogalates',
          value: 'employeeMeta.groupTextYogalates',
        },
        {
          text: 'Strength',
          value: 'employeeMeta.groupTextStrength',
        },
        {
          text: 'Prenatal',
          value: 'employeeMeta.groupTextPrenatal',
        }
        ,
        {
          text: 'Yin',
          value: 'employeeMeta.groupTextYin',
        }
        ,
        {
          text: 'Baby',
          value: 'employeeMeta.groupTextBaby',
        }
        ,
        {
          text: 'Meditation',
          value: 'employeeMeta.groupTextMeditation',
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      employees: 'employee/list',
    }),
    employeesLoading() {
      return this.$store.state['employee'].isFindPending
    },
  },

  methods: {
    async updateEmployeeMetaNaughty(id, value, index) {
      this.employees[index].employeeMeta.isNaughtyList = !value
      this.$store.dispatch('employee-meta/patch', [id, { isNaughtyList: !value }, {}])
    },
    async updateEmployeeMetaEnabled(id, value, index) {
      this.employees[index].employeeMeta.groupTextEnabled = !value
      this.$store.dispatch('employee-meta/patch', [id, { groupTextEnabled: !value }, {}])
    },
    async updateEmployeeMetaHotRa(id, value, index) {
      this.employees[index].employeeMeta.groupTextHotRa = value
      this.$store.dispatch('employee-meta/patch', [id, { groupTextHotRa: value }, {}])
    },
    async updateEmployeeMetaVinyasa(id, value, index) {
      this.employees[index].employeeMeta.groupTextVinyasa = value
      this.$store.dispatch('employee-meta/patch', [id, { groupTextVinyasa: value }, {}])
    },
    async updateEmployeeMetaYogalates(id, value, index) {
      this.employees[index].employeeMeta.groupTextYogalates = value
      this.$store.dispatch('employee-meta/patch', [id, { groupTextYogalates: value }, {}])
    },
    async updateEmployeeMetaStrength(id, value, index) {
      this.employees[index].employeeMeta.groupTextStrength = value
      this.$store.dispatch('employee-meta/patch', [id, { groupTextStrength: value }, {}])
    },
    async updateEmployeeMetaPrenatal(id, value, index) {
      this.employees[index].employeeMeta.groupTextPrenatal = value
      this.$store.dispatch('employee-meta/patch', [id, { groupTextPrenatal: value }, {}])
    },
    async updateEmployeeMetaYin(id, value, index) {
      this.employees[index].employeeMeta.groupTextYin = value
      this.$store.dispatch('employee-meta/patch', [id, { groupTextYin: value }, {}])
    },
    async updateEmployeeMetaBaby(id, value, index) {
      this.employees[index].employeeMeta.groupTextBaby = value
      this.$store.dispatch('employee-meta/patch', [id, { groupTextBaby: value }, {}])
    },
    async updateEmployeeMetaMeditation(id, value, index) {
      this.employees[index].employeeMeta.groupTextMeditation = value
      this.$store.dispatch('employee-meta/patch', [id, { groupTextMeditation: value }, {}])
    },
  },

  async beforeMount() {
    this.$store.dispatch('employee/find', {
      query: {
        limit: 300,
        include: 'user,public_profile',
        can_instruct_classes: true,
        is_active: true,
      }
    })
  }
}
</script>

<style lang="scss">

</style>