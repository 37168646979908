var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container"},[_c('v-data-table',{attrs:{"options":{ itemsPerPage: -1 },"dense":"","sort-by":"purchaseDate","headers":_vm.headers,"items":_vm.filteredResults},scopedSlots:_vm._u([{key:"item.purchaseDate",fn:function(ref){
var item = ref.item;
return [(item)?_c('span',[_vm._v(_vm._s(_vm.purchaseFormattedDate(item.purchaseDate)))]):_vm._e()]}},{key:"item.marianaCustomerName",fn:function(ref){
var item = ref.item;
return [(item)?_c('a',{attrs:{"href":("https://rayoga.marianatek.com/admin/user/profile/" + (item.marianaCustomerId) + "/payment-methods"),"target":"_blank"}},[_vm._v(" "+_vm._s(item.marianaCustomerName)+" ")]):_vm._e()]}},{key:"item.ninetyDayCurrentMembership",fn:function(ref){
var item = ref.item;
return [(item)?_c('v-select',{staticClass:"pt-6",attrs:{"outlined":"","dense":"","clearable":"","value":item.ninetyDayCurrentMembership,"items":_vm.purchaseHistoryOptions},on:{"change":function($event){return _vm.updatePurchaseHistory($event, item.id)}}}):_vm._e()]}},{key:"item.ninetyDayComments",fn:function(ref){
var item = ref.item;
return [(item.ninetyDayComments === null || item.ninetyDayComments === '')?_c('v-btn',{attrs:{"x-small":"","depressed":"","block":"","color":"primary"},on:{"click":function($event){return _vm.openCommentDialog(item)}}},[_vm._v(" Add Comments ")]):_vm._e(),(item.ninetyDayComments !== null && item.ninetyDayComments.length > 0)?_c('v-btn',{attrs:{"x-small":"","depressed":"","block":"","color":"green"},on:{"click":function($event){return _vm.openCommentDialog(item)}}},[_vm._v(" View Comments ")]):_vm._e()]}},{key:"item.ninetyDayCompleted",fn:function(ref){
var item = ref.item;
return [(item)?_c('v-btn',{attrs:{"x-small":"","color":"success","depressed":""},on:{"click":function($event){return _vm.submitItem($event, item.id)}}},[_vm._v(" Save ")]):_vm._e()]}}])}),(_vm.commentsDialog)?_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.commentsDialog),callback:function ($$v) {_vm.commentsDialog=$$v},expression:"commentsDialog"}},[_c('v-card',{staticClass:"pa-5"},[_c('v-card-title',[_vm._v(" Add Comments for "+_vm._s(_vm.commentsDialogItem.marianaCustomerName)+" ")]),_c('v-textarea',{attrs:{"outlined":""},model:{value:(_vm.tempFirstCallComments),callback:function ($$v) {_vm.tempFirstCallComments=$$v},expression:"tempFirstCallComments"}}),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.updateNinetyDayComments($event, _vm.commentsDialogItem.id)}}},[_vm._v(" Save ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }