<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="1" class="pa-4 mb-6">
          <v-col cols="12">
            <recent-ryl-syncs />
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RecentRylSyncs from '../components/tools/recentRylSyncs.vue'
export default {
  name: 'RylSyncPage',

  components: {
    RecentRylSyncs
  }
}
</script>

<style lang="scss">

</style>