import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardPage from '../views/DashboardPage.vue'
import EmployeesPage from '../views/EmployeesPage.vue'
import Auth from '../views/Auth.vue'
import AccountUsersPage from '../views/AccountUsersPage.vue'
import WorkshopCreditPage from '../views/WorkshopCreditPage.vue'
import InAppRoutePage from '../views/InAppAdPage.vue'
import FifthTabPostsPage from '../views/FifthTabPostsPage.vue'
import OAuthCallbackPage from '../views/OAuthCallbackPage.vue'
import AutolayoutPage from '../views/AutolayoutPage.vue'
import UrlGeneratorPage from '../views/UrlGeneratorPage.vue'
import RylSyncPage from '../views/RylSyncPage.vue'
import AnalyticsLandingPage from '../views/AnalyticsLandingPage.vue'
import ChallengeSignupPage from '../views/ChallengeSignupPage.vue'
import TeacherClassConfirmPage from '../views/layouts/TeacherClassConfirmPage.vue'
import IntroOffersPage from '../views/layouts/IntroOffersPage.vue'

import FiveDayTable from '../components/intro-offer-customer/FiveDayTable.vue'
import FifteenDayTable from '../components/intro-offer-customer/FifteenDayTable.vue'
import ThirtyDayTable from '../components/intro-offer-customer/ThirtyDayTable.vue'
import NinetyDayTable from '../components/intro-offer-customer/NinetyDayTable.vue'
import OneYearTable from '../components/intro-offer-customer/OneYearTable.vue'

Vue.use(VueRouter)
//this is the router
const routes = [
  {
    path: '/',
    name: 'Home',
    component: DashboardPage,
    meta: { requiresAuth: false }
  },
  {
    path: '/dashboard',
    name: 'DashboardRoute',
    component: DashboardPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/employees',
    name: 'EmployeesRoute',
    component: EmployeesPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'LoginRoute',
    component: Auth,
    meta: { requiresAuth: false }
  },
  {
    path: '/oauth/callback',
    name: 'OAuthCallbackRoute',
    component: OAuthCallbackPage,
    meta: { requiresAuth: false },
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPasswordRoute',
    component: Auth,
    meta: { requiresAuth: false }
  },
  {
    path: '/account/users',
    name: 'AccountUsersRoute',
    component: AccountUsersPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/workshop-credit',
    name: 'WorkshopCreditRoute',
    component: WorkshopCreditPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/in-app-ad',
    name: 'InAppAdRoute',
    component: InAppRoutePage,
    meta: { requiresAuth: true },
  },
  {
    path: '/fifth-tab-posts',
    name: 'FifthTabPostsRoute',
    component: FifthTabPostsPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/autolayout',
    name: 'AutolayoutRoute',
    component: AutolayoutPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/url-generator',
    name: 'UrlGeneratorRoute',
    component: UrlGeneratorPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/ryl-sync',
    name: 'RylSyncRoute',
    component: RylSyncPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/analytics/landing',
    name: 'AnalyticsLandingRoute',
    component: AnalyticsLandingPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/challenges/signups',
    name: 'ChallengeSignupRoute',
    component: ChallengeSignupPage,
    meta: { requiresAuth: true },
  },

  {
    path: '/teacher-class-confirm/:id',
    name: 'TeacherClassConfirmRoute',
    component: TeacherClassConfirmPage,
    meta: { requiresAuth: false },
  },

  {
    path: '/intro-offers',
    name: 'IntroOffersRoute',
    component: IntroOffersPage,
    meta: { requiresAuth: true },

    children: [
      {
        path: '5day',
        name: 'IntroOffers5DayRoute',
        component: FiveDayTable,
        meta: { requiresAuth: true },
      },
      {
        path: '15day',
        name: 'IntroOffers15DayRoute',
        component: FifteenDayTable,
        meta: { requiresAuth: true },
      },
      {
        path: '30day',
        name: 'IntroOffers30DayRoute',
        component: ThirtyDayTable,
        meta: { requiresAuth: true },
      },
      {
        path: '90day',
        name: 'IntroOffers90DayRoute',
        component: NinetyDayTable,
        meta: { requiresAuth: true },
      },
      {
        path: '365day',
        name: 'IntroOffers365DayRoute',
        component: OneYearTable,
        meta: { requiresAuth: true },
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
