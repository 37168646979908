<template>
  <div id="side-menu">
    <div class="side-menu-scroll">
      <v-list dense>
        <v-list-item link v-for="link in links" :key="link.name" @click="goTo(link.name)">
          <v-list-item-content>
            <v-list-item-title>
              {{link.text}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider class="mx-4"/>
      <v-list dense>
        <v-subheader>MARKETING</v-subheader>
        <v-list-item link v-for="link in marketingLinks" :key="link.name" @click="goTo(link.name)">
          <v-list-item-content>
            <v-list-item-title>
              {{link.text}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider class="mx-4"/>
      <v-list dense>
        <v-subheader>WORKFLOWS</v-subheader>
        <v-list-item link v-for="link in workflowLinks" :key="link.name" @click="goTo(link.name)">
          <v-list-item-content>
            <v-list-item-title>
              {{link.text}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider class="mx-4"/>
      <v-list dense>
        <v-subheader>REPORTS</v-subheader>
        <v-list-item link v-for="link in reportLinks" :key="link.name" @click="goTo(link.name)">
          <v-list-item-content>
            <v-list-item-title>
              {{link.text}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider class="mx-4"/>
      <v-list dense>
        <v-subheader>TOOLS</v-subheader>
        <v-list-item link v-for="link in toolsLinks" :key="link.name" @click="goTo(link.name)">
          <v-list-item-content>
            <v-list-item-title>
              {{link.text}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideMenu',
  data: () => ({
    selectedItem: 1,
    links: [
      {
        text: 'Dashboard',
        name: 'DashboardRoute',
        exact: true,
      },
      {
        text: 'Employees',
        name: 'EmployeesRoute',
        exact: true,
      },
      {
        text: 'Challenges',
        name: 'ChallengeSignupRoute',
        exact: true,
      },
    ],
    marketingLinks: [
      {
        text: 'Intro Offers CRM',
        name: 'IntroOffers5DayRoute',
        exact: true,
      },
      {
        text: 'In-app Ads',
        name: 'InAppAdRoute',
        exact: true,
      },
      {
        text: 'Fifth Tab Posts',
        name: 'FifthTabPostsRoute',
        exact: true,
      },
    ],
    workflowLinks: [
      {
        text: 'Workshop Credits',
        name: 'WorkshopCreditRoute',
        exact: true,
      },
    ],
    reportLinks: [
      {
        text: 'Autolayout',
        name: 'AutolayoutRoute',
        exact: true,
      },
      {
        text: 'Landing Analytics',
        name: 'AnalyticsLandingRoute',
        exact: true,
      },
    ],
    toolsLinks: [
      {
        text: 'RYL Sync',
        name: 'RylSyncRoute',
        exact: true,
      },
      {
        text: 'URL Generator',
        name: 'UrlGeneratorRoute',
        exact: true,
      },
    ],
  }),
  computed: {
    isAuthenticated() {
      return this.$store.state.auth.accessToken
    },
  },
  methods: {
    goTo(name) {
      this.$router.push({ name })
    },
  },
}
</script>

<style lang="sass">
#side-menu
  position: fixed
  top: 64px
  height: calc(100vh - 64px)
  width: 240px
  overflow-y: scroll

.theme--dark.v-application
  #side-menu
    background-color: #1E1E1E

.theme--light.v-application
  #side-menu
    background-color: #FFFFFF
    box-shadow: 1px 3px 14px rgba(0,0,0,0.2)
</style>