<template>
  <v-container>
    <v-row justify="center">
      <v-flex xs5>
        <login-form />
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
import LoginForm from '@/components/auth/loginForm'

export default {
  name: 'Auth',
  components: {
    LoginForm,
  },
}
</script>

<style lang="sass" scoped>

</style>