<template>
  <div class="account-users">
    <v-container>
      <v-row>
        <v-col>
          <account-users-list />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AccountUsersList from '@/components/user/accountUsersList'

export default {
  name: 'AccountUsersPage',
  components: {
    AccountUsersList,
  },
}
</script>

<style lang="sass">

</style>