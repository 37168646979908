<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="mb-5">
        <v-sheet elevation="1" class="pa-4">
          <v-col cols="12">
            <v-row>
              <v-col>
                <h2>URL Generator</h2>
                <v-divider class="mb-5"/>
              </v-col>
            </v-row>
            <v-form>
              <v-row>
                <v-col cols="10">
                  <v-text-field readonly outlined v-model="generatedUrl" />
                </v-col>
                <v-col cols="2">
                  <v-btn x-large block @click="copy" color="primary"><v-icon>content_copy</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select outlined @change="getProducts()" label="Product Type" v-model="selectedProductType" :items="productTypes" item-text="text" item-value="value" />
                </v-col>
                <v-col>
                  <v-select v-if="selectedProductType === null" outlined disabled />
                  <v-select v-if="selectedProductType === 'contracts'" :loading="contractsLoading" outlined label="Contracts" v-model="selectedProduct" :items="contracts" item-text="attributes.name" item-value="id" />
                  <v-select v-if="selectedProductType === 'products'" :loading="productsLoading" outlined label="Products" v-model="selectedProduct" :items="products" item-text="attributes.title" item-value="id" />
                  <v-select v-if="selectedProductType === 'credits'" :loading="creditsLoading" outlined label="Credits" v-model="selectedProduct" :items="credits" item-text="attributes.name" item-value="id" />
                  <v-select v-if="selectedProductType === 'packages'" :loading="packagesLoading" outlined label="Packages" v-model="selectedProduct" :items="packages" item-text="attributes.title" item-value="id" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox label="Login Only" @change="requireSignup = !loginOnly" v-model="loginOnly" />
                </v-col>
                <v-col>
                  <v-checkbox label="Require Signup" @change="loginOnly = !requireSignup" v-model="requireSignup" />
                </v-col>
                <v-col>
                  <v-checkbox label="Save Card" v-model="requireCard" />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field type="date" label="Activation Date" outlined v-model="activationDate" />
                </v-col>
                <v-col>
                  <v-text-field type="date" label="Deactivation Date" outlined v-model="deactivationDate" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined label="Coupon" v-model="coupon" />
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined label="Banner Text" v-model="bannerText" />
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined hint="Recommend 400x500 pixels" label="Desktop Image URL" v-model="desktopImageUrl" />
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined hint="Recommend 1920x1080 pixels" label="Mobile Image URL" v-model="mobileImageUrl" />
                </v-col>
              </v-row>
              <v-row justify="end">
                <v-col cols="3">
                  <v-btn color="primary" large block depressed @click="createConfig">Create</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-sheet>
      </v-col>

      <v-col cols="12">
        <v-sheet elevation="1" class="pa-4">


          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Banner Text</th>
                    <th class="text-left">
                      Mtek ID
                    </th>
                    <th class="text-left">
                      Activation
                    </th>
                    <th>Deactivation</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in landingPageConfigs"
                    :key="item.id"
                  >
                    <td>{{item.bannerText}}</td>
                    <td>{{item.mtekId}}</td>
                    <td>{{DateTime.fromISO(item.activationDate).toLocaleString(DateTime.DATETIME_FULL)}}</td>
                    <td>{{DateTime.fromISO(item.deactivationDate).toLocaleString(DateTime.DATETIME_FULL)}}</td>
                    <td><v-btn icon depressed :href="demoUrl(item)" target="_blank"><v-icon>mdi-export</v-icon></v-btn></td>
                    <!--<td><v-btn icon depressed @click="copyDemo(demoUrl(item))"><v-icon>content_copy</v-icon></v-btn></td>-->
                    <td><v-btn icon depressed @click.prevent="openEdit(item)"><v-icon>edit</v-icon></v-btn></td>
                    <td><v-btn icon depressed @click.prevent="openRemoveDialog(item.id)"><v-icon>delete</v-icon></v-btn></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

        </v-sheet>
      </v-col>
      <v-dialog width="650" v-model="editIsOpen">
        <v-sheet class="pa-6">
          <v-form>
            <v-row>
              <v-col>
                <v-checkbox label="Login Only" @change="edit.requireSignup = !loginOnly" v-model="loginOnly" />
              </v-col>
              <v-col>
                <v-checkbox label="Require Signup" @change="edit.loginOnly = !requireSignup" v-model="requireSignup" />
              </v-col>
              <v-col>
                <v-checkbox label="Save Card" v-model="edit.requireCard" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field type="date" label="Activation Date" outlined v-model="edit.activationDate" />
              </v-col>
              <v-col>
                <v-text-field type="date" label="Deactivation Date" outlined v-model="edit.deactivationDate" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field outlined label="Coupon" v-model="edit.coupon" />
              </v-col>
              <v-col cols="12">
                <v-text-field outlined label="Banner Text" v-model="edit.bannerText" />
              </v-col>
              <v-col cols="12">
                  <v-text-field outlined hint="Recommend 400x500 pixels" label="Desktop Image URL" v-model="edit.desktopImageUrl" />
                </v-col>
                <v-col cols="12">
                  <v-text-field outlined hint="Recommend 1920x1080 pixels" label="Mobile Image URL" v-model="edit.mobileImageUrl" />
                </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="3">
                <v-btn color="primary" large block depressed @click="update">Save</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-sheet>
      </v-dialog>

      <v-dialog persistent max-width="300" v-model="confirmDeleteIsOpen">
        <v-card class="pa-6">
          <v-card-title>
            Are you sure you want to delete this configuration?
          </v-card-title>
          <v-card-actions>
            <v-btn color="gray" @click="cancelRemove">Cancel</v-btn>
            <v-btn color="red" @click="remove(deleteId)">Confirm</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'

export default {
  components: {
  },
  name: 'UrlGeneratorPage',
  data() {
    return {
      DateTime: DateTime,
      productTypes: [
        { text: 'Contract', value: 'contracts' },
        { text: 'Credit', value: 'credits' },
        //{ text: 'Product', value: 'products' },
        { text: 'Package', value: 'packages' },
      ],
      selectedProductType: null,
      selectedProduct: null,
      requireSignup: false,
      requireCard: false,
      loginOnly: false,
      coupon: null,
      bannerText: null,
      desktopImageUrl: null,
      mobileImageUrl: null,
      activationDate: null,
      deactivationDate: null,
      confirmDeleteIsOpen: false,
      deleteId: null,
      editIsOpen: false,
      edit: {
        requireSignup: false,
        requireCard: false,
        loginOnly: false,
        coupon: null,
        bannerText: null,
        desktopImageUrl: null,
        mobileImageUrl: null,
        activationDate: null,
        deactivationDate: null,
      }
    }
  },

  computed: {
    ...mapGetters({
      contracts: 'contract/list',
      products: 'product/list',
      credits: 'credit/list',
      packages: 'package/list',
      landingPageConfigs: 'landing-page-config/list',
    }),
    contractsLoading() {
      return this.$store.state['contract'].isFindPending
    },
    productsLoading() {
      return this.$store.state['product'].isFindPending
    },
    creditsLoading() {
      return this.$store.state['credit'].isFindPending
    },
    packagesLoading() {
      return this.$store.state['package'].isFindPending
    },
    landingPageConfigsFindPending() {
      return this.$store.state['landing-page-config'].isFindPending
    },
    landingPageConfigCreatePending() {
      return this.$store.state['landing-page-config'].isCreatePending
    },
    generatedUrl() {
      const mtekId = `?mtek_id=${this.selectedProductType}-${this.selectedProduct}`
      const loginOnly = `&login_only=${(this.loginOnly) ? 1 : 0}`
      const requireSignup = `&require_signup=${(this.requireSignup) ? 1 : 0}`
      const requireCard = `&require_card=${(this.requireCard) ? 1 : 0}`
      const coupon = (this.coupon) ? `&coupon=${this.coupon}` : ''
      const bannerText = (this.bannerText) ? `&banner_text=${this.bannerText}` : ''
      const desktopImageUrl = (this.desktopImageUrl) ? `&desktop_image_url=${this.desktopImageUrl}` : ''
      const mobileImageUrl = (this.mobileImageUrl) ? `&mobile_image_url=${this.mobileImageUrl}` : ''

      return `https://landing.rayoga.com/${mtekId}${loginOnly}${requireSignup}${requireCard}${coupon}${bannerText}${desktopImageUrl}${mobileImageUrl}`
    },
  },

  methods: {
    getProducts() {
      if (this.selectedProductType === 'contracts') {
        this.$store.dispatch('contract/find', {
          query: {
            limit: 200,
          }
        })
      } else if (this.selectedProductType === 'products') {
        this.$store.dispatch('product/find', {
          query: {
            limit: 200,
          }
        })
      } else if (this.selectedProductType === 'credits') {
        this.$store.dispatch('credit/find', {
          query: {
            limit: 200,
          }
        })
      } else if (this.selectedProductType === 'packages') {
        this.$store.dispatch('package/find', {
          query: {
            limit: 200,
          }
        })
      }
    },

    openEdit(item) {
      this.edit = {
        id: item.id,
        requireSignup: item.requireSignup,
        requireCard: item.requireCard,
        loginOnly: item.loginOnly,
        coupon: item.coupon,
        bannerText: item.bannerText,
        desktopImageUrl: item.desktopImageUrl,
        mobileImageUrl: item.mobileImageUrl,
        activationDate: item.activationDate,
        deactivationDate: item.deactivationDate,
      }

      this.edit.activationDate = DateTime.fromISO(item.activationDate).toFormat('yyyy-MM-dd')
      this.edit.deactivationDate = DateTime.fromISO(item.deactivationDate).toFormat('yyyy-MM-dd')
      this.editIsOpen = true
    },

    async update() {
      if (this.edit.activationDate) this.edit.activationDate = DateTime.fromISO(this.edit.activationDate).setZone('America/Los_Angeles').toUTC().toISO()
      if (this.edit.deactivationDate) this.edit.deactivationDate = DateTime.fromISO(this.edit.deactivationDate).setZone('America/Los_Angeles').endOf('day').toISO()
      await this.$store.dispatch('landing-page-config/patch', [this.edit.id, this.edit])
      this.edit = {}
      this.editIsOpen = false
    },

    copy() {
      navigator.clipboard.writeText(this.generatedUrl)
    },

    copyDemo(demo) {
      navigator.clipboard.writeText(demo)
    },

    demoUrl(config) {
      /*const mtekId = `?mtek_id=${config.mtekId}`
      const loginOnly = `&login_only=${(config.loginOnly) ? 1 : 0}`
      const requireSignup = `&require_signup=${(config.requireSignup) ? 1 : 0}`
      const requireCard = `&require_card=${(config.requireCard) ? 1 : 0}`
      const coupon = (config.coupon) ? `&coupon=${config.coupon}` : ''
      const bannerText = (config.bannerText) ? `&banner_text=${config.bannerText}` : ''
      const desktopImageUrl = (config.desktopImageUrl) ? `&desktop_image_url=${config.desktopImageUrl}` : ''
      const mobileImageUrl = (config.mobileImageUrl) ? `&mobile_image_url=${config.mobileImageUrl}` : ''*/

      //return `https://landing.rayoga.com/${mtekId}${loginOnly}${requireSignup}${requireCard}${coupon}${bannerText}${desktopImageUrl}${mobileImageUrl}`
      return `https://landing.rayoga.com?promo_id=${config.id}`
    },

    async createConfig() {
      this.$store.dispatch('landing-page-config/create', {
        mtekId: `${this.selectedProductType}-${this.selectedProduct}`,
        coupon: this.coupon,
        requireSignup: (this.requireSignup) ? 1 : 0,
        saveCard: (this.saveCard) ? 1 : 0,
        loginOnly: (this.loginOnly) ? 1 : 0,
        bannerText: this.bannerText,
        desktopImageUrl: this.desktopImageUrl,
        mobileImageUrl: this.mobileImageUrl,
        activationDate: DateTime.fromISO(this.activationDate).setZone('America/Los_Angeles').toUTC().toISO(),
        deactivationDate: DateTime.fromISO(this.deactivationDate).setZone('America/Los_Angeles').endOf('day').toISO(),
      })
    },

    openRemoveDialog(id) {
      this.deleteId = id
      this.confirmDeleteIsOpen = true
    },

    cancelRemove() {
      this.deleteId = null
      this.confirmDeleteIsOpen = false
    },

    async remove(id) {
      await this.$store.dispatch('landing-page-config/remove', [id])
      this.confirmDeleteIsOpen = false
    },
  },
  beforeMount() {
    this.$store.dispatch('landing-page-config/find')
  }
}
</script>

<style lang="scss">

</style>