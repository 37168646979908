// src/store/index.js
import Vue from 'vue'
import Vuex from 'vuex'
import { FeathersVuex } from '@/plugins/feathers-client'
import auth from './store.auth'
import meta from './store.meta'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
Vue.use(FeathersVuex)

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /\.js$/
)
const servicePlugins = requireModule
  .keys()
  .map(modulePath => requireModule(modulePath).default)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    meta
  },
  plugins: [...servicePlugins, auth, createPersistedState({
    paths: ['auth', 'meta']
  })]
})
