<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="1" class="pa-4">
          <v-col cols="12">
            <classroom-threshold-form />
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="1" class="pa-4">
          <v-col cols="12">
            <autolayout-table />
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import classroomThresholdForm from '../components/classroomThresholdForm.vue'
import autolayoutTable from '../components/reports/autolayoutTable.vue'

export default {
  components: {
    classroomThresholdForm,
    autolayoutTable
  },
  name: 'AutolayoutPage',
}
</script>

<style lang="scss">

</style>