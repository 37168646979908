<template>
  <div class="teacher-class-confirm-page">
    <v-main class="lighten-3">
      <v-container v-if="alert">
        <v-row class="justify-center text-center" v-if="alert.response === 'PENDING'">
          <v-col lg="6" md="8" sm="10" xs="12">
            <h1>Please confirm or cancel</h1>
            <br />
            <p>{{ alert.marianaClassName }}<br />{{ getDate(alert.marianaStartDatetime) }}<br />{{ alert.marianaClassLocation }}</p>

            <v-row class="justify-center pt-4">
              <v-col cols="4">
                <v-btn x-large color="green" @click.native="confirm">CONFIRM</v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn x-large color="red" @click.native="cancel">CANCEL</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="justify-center text-center" v-if="alert.response === 'CONFIRM'">
          <v-col lg="6" md="8" sm="10" xs="12">
            <h1>Your class has been confirmed!</h1>
          </v-col>
        </v-row>

        <v-row class="justify-center text-center" v-if="alert.response === 'CANCEL'">
          <v-col lg="6" md="8" sm="10" xs="12">
            <p>Since you can't teach this class, go to When I Work immediately and drop the class. Use Ra Yoga's Group Text feature as needed to find coverage.</p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'

export default {
  name: 'TeacherClassConfirmPage',

  computed: {
    ...mapGetters({
      teacherClassAlert: 'teacher-class-alert/list'
    }),

    alert() {
      return this.teacherClassAlert[0]
    }
  },

  methods: {
    confirm() {
      this.$store.dispatch('teacher-class-alert/patch', [this.alert.id, {
        response: 'CONFIRM'
      }])
    },
    cancel() {
      this.$store.dispatch('teacher-class-alert/patch', [this.alert.id, {
        response: 'CANCEL'
      }])
    },
    getDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT)
    }
  },

  mounted() {
    this.$store.dispatch('teacher-class-alert/find', {
      query: {
        marianaClassSessionId: this.$route.params.id
      }
    })
  }
}
</script>