<template>
  <div class="sessionUserMenu">
    <v-menu
      offset-y
      nudge-bottom="5"
      rounded
      min-width="200"
      z-index="0">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="transparent"
          rounded
          depressed
          left
          large
          v-bind="attrs"
          v-on="on">
            <v-avatar size="38" class="mr-1">
              <v-img contain src="https://via.placeholder.com/200x200"></v-img>
            </v-avatar>
            <v-icon medium>arrow_drop_down</v-icon>
        </v-btn>
      </template>
      <v-list elevation="1s" class="session-user-dropdown">
        <v-list-item class="session-user-dropdown__user-item" three-line dark>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{ sessionUser.firstName }} {{ sessionUser.lastName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <router-link
                class="account-settings-link"
                :to="{ name: 'ProfilePage' }">
                Profile Settings
              </router-link>
            </v-list-item-subtitle>
            <v-list-item-action>
              <v-switch label="Dark Mode" @change="darkMode" />
            </v-list-item-action>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="navigateTo('AccountUsersRoute')">Users</v-list-item>
        <v-list-item @click="navigateTo('Notifications')">Notifications</v-list-item>
        <v-list-item @click="navigateTo('Subscriptions')">Subscription</v-list-item>
        <!--<v-list-item @click="navigateTo('Locations')">Locations</v-list-item>-->
        <v-list-item @click="submitLogout">
          Logout
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'SessionUserMenu',
  computed: {
    sessionUser() {
      return this.$store.state.auth.user
    },
  },
  methods: {
    async submitLogout() {
      await this.$store.dispatch('auth/logout')
      location.reload();
    },
    navigateTo(name) {
      this.$router.push({ name })
    },
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
  },
}
</script>

<style lang="sass" scoped>
.session-user-dropdown
  padding-top: 0

  .session-user-dropdown__user-item
    background: #255879

  .account-settings-link
    color: #ffffff

  .v-list-item--link:before
    background-color: #3EA3A4

  .theme--light.v-list-item:hover::before
    opacity: 0.4
</style>