<template>
  <div class="table-container">
    <v-data-table
      v-if="filteredResults"
      :options="{ itemsPerPage: -1 }"
      dense
      sort-by="purchaseDate"
      :headers="headers"
      :items="filteredResults">
      <template v-slot:item.purchaseDate="{ item }">
        <span v-if="item">{{ purchaseFormattedDate(item.purchaseDate) }}</span>
      </template>
      <template v-slot:item.marianaCustomerName="{ item }">
        <a v-if="item" :href="`https://rayoga.marianatek.com/admin/user/profile/${item.marianaCustomerId}/payment-methods`" target="_blank">
          {{ item.marianaCustomerName }}
        </a>
      </template>
      <template v-slot:item.fifteenDayClassesTaken="{ item }">
        <v-text-field
          v-if="item"
          @change="updateClassesTaken($event, item.id)"
          class="pt-6"
          outlined
          dense
          width="60"
          :value="item.fifteenDayClassesTaken"
          type="number"
        />
      </template>
      <template v-slot:item.fifteenDayMultiStudio="{ item }">
        <v-checkbox v-if="item" v-model="item.fifteenDayMultiStudio" @change="updateMultiStudio($event, item.id)" />
      </template>
      <template v-slot:item.fifteenDayPurchaseHistory="{ item }">
        <v-select
          v-if="item"
          class="pt-6"
          outlined
          dense
          clearable
          :value="item.fifteenDayPurchaseHistory"
          :items="purchaseHistoryOptions"
          @change="updatePurchaseHistory($event, item.id)"
        />
      </template>
      <template v-slot:item.fifteenDayCompleted="{ item }">
        <v-btn v-if="item" x-small color="success" depressed @click="submitItem($event, item.id)">
          Save
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'

export default {
  name: 'FifteenDayTable',

  props: [
    'customers'
  ],

  data() {
    return {
      headers: [
        {
          text: 'Purchase Date',
          value: 'purchaseDate',
        },
        {
          text: 'Customer',
          value: 'marianaCustomerName',
        },
        {
          text: 'Classes Taken on Intro Offer',
          value: 'fifteenDayClassesTaken',
        },
        {
          text: 'Multi-studio',
          value: 'fifteenDayMultiStudio'
        },
        {
          text: 'Purchase History',
          value: 'fifteenDayPurchaseHistory'
        },
        {
          text: '',
          value: 'fifteenDayCompleted'
        }
      ],
      purchaseTypeOptions: [
      {
          text: 'In Studio',
          value: 'In Studio',
        },
        {
          text: 'Web (ECCO)',
          value: 'Web (ECCO)',
        },
        {
          text: 'Self Bought',
          value: 'Self Bought',
        },
      ],
      purchaseHistoryOptions: [
        {
          text: 'Essential Limited',
          value: 'Essential Limited',
        },
        {
          text: 'Essential Unlimited',
          value: 'Essential Unlimited',
        },
        {
          text: 'Plus Limited',
          value: 'Plus Limited',
        },
        {
          text: 'Plus Unlimited',
          value: 'Plus Unlimited',
        },
        {
          text: 'Premium Limited',
          value: 'Premium Limited',
        },
        {
          text: 'Premium Unlimited',
          value: 'Premium Unlimited',
        },
        {
          text: 'Groupon',
          value: 'Groupon',
        },
        {
          text: 'ClassPass',
          value: 'ClassPass',
        },
        {
          text: 'Class Package',
          value: 'Class Package',
        },
        {
          text: 'Other',
          value: 'Other',
        },
        {
          text: 'Nothing Yet',
          value: 'Nothing Yet',
        },
      ]
    }
  },

  computed: {
    ...mapGetters({
      introOfferCustomers: 'intro-offer-customer/list'
    }),

    selectedStudio() {
      return this.$store.state.meta.introOfferSelectedStudio
    },

    filteredResults() {
      let filtered = this.introOfferCustomers

      filtered = filtered.filter(o => o.purchaseLocation === this.selectedStudio)
      filtered = filtered.filter(o => o.purchaseDate <= DateTime.now().minus({ 'day': 15 }).toISO())
      filtered = filtered.filter(o => o.fiveDayCompleted === true)
      filtered = filtered.filter(o => o.fifteenDayCompleted === false)

      return filtered
    }
  },

  watch: {
    selectedStudio() {
      this.findIntroOfferCustomers()
    }
  },

  methods: {
    purchaseFormattedDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)
    },
    async findIntroOfferCustomers() {
      await this.$store.commit('intro-offer-customer/clearAll', this.$store.state['intro-offer-customer'])
      this.$store.dispatch('intro-offer-customer/find', {
        query: {
          $limit: 500,
          purchaseLocation: this.selectedStudio,
          purchaseDate: {
            $lte: DateTime.now().minus({ 'day': 15 })
          },
          fiveDayCompleted: true,
          fifteenDayCompleted: {
            $ne: true,
          }
        }
      })
    },
    async updateMultiStudio(item, id) {
      this.$store.dispatch('intro-offer-customer/patch', [id, { fifteenDayMultiStudio: item }])
    },
    async updatePurchaseHistory(item, id) {
      this.$store.dispatch('intro-offer-customer/patch', [id, { fifteenDayPurchaseHistory: item }])
    },
    async updateClassesTaken(item, id) {
      this.$store.dispatch('intro-offer-customer/patch', [id, { fifteenDayClassesTaken: item }])
    },
    async submitItem(item, id) {
      this.$store.dispatch('intro-offer-customer/patch', [id, { fifteenDayCompleted: true }])
      this.findIntroOfferCustomers()
    }
  },

  mounted() {
    this.findIntroOfferCustomers()
  },

  destroyed() {
    this.$store.commit('intro-offer-customer/clearAll', this.$store.state['intro-offer-customer'])
  }
}
</script>