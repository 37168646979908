<template>
  <v-form @submit.prevent="create">
    <v-row>
      <v-col cols="12">
        <h2>Notifications</h2>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row align-content="end">
      <v-col cols="9">
        <v-text-field
          v-model="email"
          label="Email Address"
        />
      </v-col>
      <v-col cols="3">
        <v-btn
          color="primary"
          :disabled="!canSubmit"
          block
          depressed
          type="submit">
          Create
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Email Address
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in workflowNotifications"
                :key="item.id"
              >
                <td>{{ item.email }}</td>
                <td><v-btn icon depressed @click.prevent="remove(item.id)"><v-icon>mdi_delete</v-icon></v-btn></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WorkflowNotificationForm',
  data() {
    return {
      email: null,
    }
  },
  computed: {
    ...mapGetters({
      workflowNotifications: 'workflow-notification/list',
    }),
    workflowNotificaitonsLoading() {
      return this.$store.state['workflow-notification'].isFindPending
    },
    canSubmit() {
      return this.email !== null
    }
  },
  methods: {
    async create() {
      await this.$store.dispatch('workflow-notification/create', {
        email: this.email,
        type: 'WORKSHOP-CREDIT'
      })

      this.email = null
    },
    async remove(id) {
      await this.$store.dispatch('workflow-notification/remove', [id])
    },
  },
  mounted() {
    this.$store.dispatch('workflow-notification/find', {
      query: {
        type: 'WORKSHOP-CREDIT',
        $limit: 50,
      }
    })
  }
}
</script>

<style lang="sass">
</style>