<template>
  <v-card light class="mx-auto mb-15" @click="goTo(post.buttonLink)">
    <v-img :src="post.imageUrl" />
  </v-card>
</template>

<script>
export default {
  name: 'postImage',
  props: [
    'post'
  ],
  methods: {
    goTo(link) {
      window.open(link, '_blank');
    },
  }
}
</script>