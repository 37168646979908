<template>
  <div class="status-icon">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" :color="iconColor">{{iconName}}</v-icon>
      </template>
      <span>{{ iconMessage }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'StatusIcon',
  props: ['customer'],
  computed: {
    iconColor() {
      switch (this.customer.status) {
        case 'COMPLETE':
          return 'green'
        case 'PENDING':
          return 'yellow'
        case 'ERROR':
          return 'yellow'
        case 'CANCELLED':
          return 'red'
        default:
          return 'gray'
      }
    },
    iconName() {
      switch (this.customer.status) {
        case 'COMPLETE':
          return 'mdi-check-circle'
        case 'PENDING':
          return 'mdi-dots-horizontal-circle-outline'
        case 'ERROR':
          return 'mdi-error'
        case 'CANCELLED':
          return 'mdi-cancel'
        default:
          return 'mdi-dots-horizontal-circle-outline'
      }
    },
    iconMessage() {
      if (this.customer.syncError && this.customer.syncError.length > 0) {
        return this.customer.syncError
      } else {
        return this.customer.status
      }
    }
  }
}
</script>