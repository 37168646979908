<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="1" class="pa-4 mb-6">
          <v-col cols="12">
            <workshop-credit-form></workshop-credit-form>
          </v-col>
        </v-sheet>
        <v-sheet elevation="1" class="pa-4">
          <v-col cols="12">
            <workflow-notification-form />
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WorkshopCreditForm from '@/components/workflows/workshop-credit/workshopCreditForm'
import WorkflowNotificationForm from '@/components/workflows/workflowNotificationForm.vue'

export default {
  name: 'WorkshopCreditPage',

  components: {
    WorkshopCreditForm,
    WorkflowNotificationForm,
  }
}
</script>

<style lang="scss">

</style>