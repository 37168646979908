<template>
  <div class="account-users-list">
    <v-list v-if="!usersLoading" two-line class="mb-15">
      <template v-for="(user, i) in users">
        <v-list-item :key="i">
          <!--<v-list-item-avatar>
            <v-img :src="user.gravatarUrl"></v-img>
          </v-list-item-avatar>-->
          <v-list-item-content>
            <v-list-item-title>
              <v-row>
                <v-col cols="4">
                  {{ user.firstName }} {{ user.lastName }}
                </v-col>
                <v-spacer />
                <v-col cols="4">
                  {{ user.role }}
                </v-col>
              </v-row>
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ user.email }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              icon
              @click="editItem(user)">
              <v-icon
                small
                class="mr-2"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="user.id" v-show="(i + 1) < users.length" />
      </template>
    </v-list>

    <v-row v-if="usersLoading" class="text-center">
      <v-col>
        <v-progress-circular indeterminate color="secondary" />
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          depressed
          large
          class="mb-2"
          v-bind="attrs"
          v-on="on"
        >
          Add User
        </v-btn>
      </template>
      <v-sheet class="pa-6">
        <v-row>
          <v-col cols="6">
            <v-text-field
              outlined
              v-model="editedItem.firstName"
              label="First Name"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              outlined
              v-model="editedItem.lastName"
              label="Last Name"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              v-model="editedItem.email"
              label="Email Address"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="editedIndex === -1">
            <v-text-field
              outlined
              type="password"
              v-model="editedItem.password"
              label="Password"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              v-model="editedItem.phone"
              label="Phone"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              outlined
              v-model="editedItem.role"
              label="User Role"
              :items="['Admin', 'Editor']"
            ></v-select>
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          @click="save"
        >
          Save
        </v-btn>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AccountUsersList',
  data() {
    return {
      dialog: false,
      editedIndex: -1,
      editedItem: {},
    }
  },
  computed: {
    ...mapGetters({
      users: 'users/list',
    }),
    usersLoading() {
      return this.$store.state.users.isFindLoading
    },
    sessionUser() {
      return this.$store.state.auth.user
    },
    formTitle () {
      return this.editedIndex === -1 ? 'New User' : 'Edit User'
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  methods: {
    editItem (item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    save () {
      const { id } = this.editedItem
      if (this.editedIndex > -1) {
        this.$store.dispatch('users/patch', [id, this.editedItem, {}])
      } else {
        this.$store.dispatch('users/create', {
          ...this.editedItem,
        })
      }
      this.close()
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
  },
  mounted() {
    this.$store.dispatch('users/find', {
      query: {
        $limit: 50,
      }
    });
  },
}
</script>

<style lang="sass">

</style>