<template>
  <div class="loginFormContainer">
    <v-sheet class="px-12 pt-9 pb-12" rounded="lg" elevation="1">
      <v-row>
        <v-col class="text--center">
          <h1 class="logo">ECCO</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h2 class="subheader mb-5">LOG IN TO YOUR ACCOUNT</h2>
        </v-col>
      </v-row>
      <v-row v-if="errorOnAuthenticate && errorOnAuthenticate.message !== 'No accessToken found in storage'">
        <v-col>
          <v-alert
            type="error"
            outlined
            dense
            dismissible>
              {{ errorOnAuthenticate.message }}
          </v-alert>
        </v-col>
      </v-row>
      <v-form class="" @submit.prevent="submitLogin">
        <v-text-field
          outlined
          type="email"
          label="E-mail"
          v-model="email" />

        <v-text-field
          outlined
          label="Password"
          type="password"
          v-model="password" />

        <v-btn
          type="submit"
          color="accent"
          block
          depressed
          x-large
          :loading="isAuthenticatePending">
          Login
        </v-btn>
      </v-form>

      <v-row class="text--center">
        <v-col cols="12" class="mt-5">
          <v-btn
          :href="marianaTekLoginHref"
          color="primary"
          block
          depressed
          x-large
          :loading="isAuthenticatePending">
          Login with MarianaTek
        </v-btn>
        </v-col>
      </v-row>

      <v-row class="text--center">
        <v-col>
          <router-link :to="{ name: 'ForgotPasswordRoute' }">Forgot Password?</router-link>
          <router-link :to="{ name: 'SignupRoute' }">Create an account</router-link>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'LoginForm',
  data() {
    return {
      email: '',
      password: '',
      clientId: 'eQfycs5zGlkhajIXy6yZszZviLkK1da3YLIcQwQS',
      redirectUri: 'https://ecco-admin.rayoga.com/oauth/callback',
      state: null,
      challenge: null,
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    }),
    ...mapState('auth', ['isAuthenticatePending', 'errorOnAuthenticate']),
    marianaTekLoginHref() {
      return `https://rayoga.marianatek.com/o/authorize?response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUri}&state=${this.state}&prompt=true&code_challenge=${this.challenge}`
    }
  },
  methods: {
    async submitLogin() {
      await this.$store.dispatch('auth/authenticate', {
        email: this.email,
        password: this.password,
        strategy: 'local',
      })

      this.email = ''
      this.password = ''

      this.$router.push({ name: 'DashboardRoute' })
    },
    dec2hex (dec) {
      return dec.toString(16).padStart(2, "0")
    },
    generateId (len) {
      var arr = new Uint8Array((len || 40) / 2)
      window.crypto.getRandomValues(arr)
      return Array.from(arr, this.dec2hex).join('')
    }
  },
  beforeMount() {
    this.challenge = this.generateId(32)
    window.localStorage.setItem('challenge', this.challenge)
    if (this.isAuthenticated) this.$router.replace({ name: 'DashboardRoute' })
  },

  mounted() {
    console.log(3)
  }
}
</script>

<style lang="sass" scoped>
#zenstream.theme--dark .loginFormContainer .logo
  filter: invert(1) grayscale(1)

.subheader
  color: #7C7C7C
  text-transform: uppercase
  text-align: center

a.router-link-active
  width: 100%
  display: block
</style>