<template>
  <div class="recent-mbo-syncs">
    <v-row>
      <v-col>
        <h2 class="mb-4">Recent RYL Syncs</h2>
      </v-col>
    </v-row>
    <v-divider class="mb-3" />
    <v-card elevation="0">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Status</th>
              <th>Name</th>
              <th>Email</th>
              <th></th>
              <th></th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="customer in customers" :key="customer.id">
              <td>
                <status-icon :customer="customer" />
              </td>
              <td>
                {{ customer.firstName }} {{ customer.lastName }}
              </td>
              <td>
                <a :href="`mailto:${customer.email}`">{{customer.email}}</a>
              </td>
              <td>
                <v-btn
                  class="mr-2"
                  x-small
                  depressed
                  color="primary"
                  target="_blank"
                  v-if="customer.zypeId"
                  :href="`https://admin.zype.com/consumers/${customer.zypeId}/edit`">
                  ZYPE
                </v-btn>
                <v-btn
                  x-small
                  depressed
                  color="primary"
                  target="_blank"
                  v-if="customer.marianaId"
                  :href="`https://rayoga.marianatek.com/admin/user/profile/${customer.marianaId}/payment-methods`">
                  MTEK
                </v-btn>
              </td>
              <td>
                <v-btn depressed color="default" x-small @click.prevent="resendPasswordCreate(customer.email)">Resend Password Create</v-btn>
              </td>
              <td><small>{{ new Date(customer.createdAt).toLocaleString() }}</small></td>
              <td><small>{{ new Date(customer.updatedAt).toLocaleString() }}</small></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import StatusIcon from '@/components/statusIcon'

export default {
  name: 'RecentRylSyncs',

  components: {
    StatusIcon,
  },

  data: () => ({
    //baseUrl: 'http://localhost:3030',
    baseUrl: 'https://ecco-api.rayoga.com',
    customers: null,
  }),

  methods: {
    async getCustomers() {
      const response = await axios.get(`${this.baseUrl}/ryl/customer?$sort[updatedAt]=-1&$limit=100`).then(response => response.data)
      console.log(response)
      this.customers = response.data.filter(customer => customer.marianaId)
    },

    async resendPasswordCreate(email) {
      await axios.post(`${this.baseUrl}/ryl/initiate-create-password`, { email, sendEmail: true })
    },
  },

  beforeMount() {
    this.getCustomers()
  }
};
</script>