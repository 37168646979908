<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="1" class="pa-4">
          <v-col cols="12">
            <h2>Challenge Signups</h2>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <v-simple-table :headers="headers" :items="employees">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th v-for="header in headers" :key="header.text">
                      {{header.text}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item, index in challengeSignups"
                    :key="index"
                  >
                    <td>{{ item.customerId }}</td>
                    <td>{{ item.customerName }}</td>
                    <td>{{ item.customerEmail }}</td>
                    <td>{{ item.referral }}</td>
                    <td><a :href="`https://rayoga.marianatek.com/admin/user/profile/${item.customerId}/payment-methods`">Mariana Profile</a></td>
                    <td>{{ item.createdAt }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
// import find from 'lodash/find'

export default {
  name: 'ChallengesPage',

  data() {
    return {
      headers: [
        {
          text: 'Mariana ID',
          value: 'customerId'
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Referral',
          value: 'referral'
        },
        {
          text: 'Mariana Profile',
          value: '',
        },
        {
          text: 'Signup Date',
          value: 'createdAt'
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      challengeSignups: 'signup/list',
    }),
    challengesLoading() {
      return this.$store.state['signup'].isFindPending
    },
  },

  methods: {
  },

  async beforeMount() {
    this.$store.dispatch('signup/find', {
      query: {
        challengeId: 2,
        $limit: 1000,
      }
    })
  }
}
</script>

<style lang="scss">

</style>