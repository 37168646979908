<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="1" class="pa-4 mb-6">
          <v-col cols="12">
            <v-form>
              <v-select
                label="Type"
                :items="types"
                v-model="type"
                item-text="text"
                item-value="value"
                outlined />

              <v-text-field
                v-model="title"
                label="Title"
                outlined />

              <v-text-field
                v-model="subtitle"
                label="Subtitle"
                outlined />

              <v-textarea
                v-model="body"
                label="Body"
                outlined />

              <v-text-field
                v-model="imageUrl"
                label="Image URL"
                outlined />

              <v-text-field
                v-model="buttonText"
                label="Button Text"
                outlined />

              <v-text-field
                v-model="buttonLink"
                label="Link"
                outlined />

              <v-text-field
                type="date"
                v-model="publishAt"
                label="Publish At"
                outlined />

              <v-text-field
                type="date"
                v-model="unpublishAt"
                label="Unpublish At"
                outlined />

              <v-btn color="primary" depressed @click="create">Create</v-btn>

            </v-form>
          </v-col>
        </v-sheet>
      </v-col>

      <v-col cols="12">
        <v-sheet elevation="1" class="pa-4 mb-6">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Title</th>
                  <th class="text-left">
                    Publish At
                  </th>
                  <th class="text-left">
                    Unpublish At
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in posts"
                  :key="item.id"
                >
                  <td>{{item.title}}</td>
                  <td>{{item.publishAt}}</td>
                  <td>{{item.unpublishAt}}</td>
                  <td><v-btn outlined small @click.prevent="openRemoveDialog(item.id)">Delete</v-btn></td>
                  <td><v-btn outlined small depressed @click.prevent="openEdit(item)">Edit</v-btn></td>
                  <td><v-btn outlined small depressed @click.prevent="openPreview(item)">Preview</v-btn></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-col>

      <v-dialog width="650" v-model="editIsOpen">
        <v-sheet class="pa-6">
          <v-form>
            <v-select
              label="Type"
              :items="types"
              v-model="edit.type"
              item-text="text"
              item-value="value"
              outlined />

            <v-text-field
              v-model="edit.title"
              label="Title"
              outlined />

            <v-text-field
              v-model="edit.subtitle"
              label="Subtitle"
              outlined />

            <v-textarea
              v-model="edit.body"
              label="Body"
              outlined />

            <v-text-field
              v-model="edit.imageUrl"
              label="Image URL"
              outlined />

            <v-text-field
              v-model="edit.buttonText"
              label="Button Text"
              outlined />

            <v-text-field
              v-model="edit.buttonLink"
              label="Link"
              outlined />

            <v-text-field
              type="date"
              v-model="edit.publishAt"
              label="Publish At"
              outlined />

            <v-text-field
              type="date"
              v-model="edit.unpublishAt"
              label="Unpublish At"
              outlined />

            <v-btn color="primary" depressed @click="update">Save</v-btn>

          </v-form>
        </v-sheet>
      </v-dialog>

      <v-dialog persistent max-width="300" v-model="confirmDeleteIsOpen">
        <v-card class="pa-6">
          <v-card-title>
            Are you sure you want to delete this post?
          </v-card-title>
          <v-card-actions>
            <v-btn color="gray" @click="cancelRemove">Cancel</v-btn>
            <v-btn color="red" @click="remove(deleteId)">Confirm</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog width="500" v-model="previewPostIsOpen">
        <v-sheet color="#f8f4ef" class="pa-6" v-if="previewPost !== null">
          <v-col cols="12">
            <post-full v-if="previewPost.body !== null" :post="previewPost"></post-full>
            <post-image v-if="previewPost.body === null" :post="previewPost"></post-image>
          </v-col>
        </v-sheet>
      </v-dialog>

    </v-row>
  </v-container>
</template>

<script>
import postFull from '@/components/fifth-tab/postFull.vue'
import postImage from '@/components/fifth-tab/postImage.vue'
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'

export default {
  name: 'FifthTabPostPage',
  components: {
    postFull,
    postImage,
  },
  data() {
    return {
      types: [
        { text: 'Marketing', value: 'MARKETING' },
        { text: 'Lifestyle', value: 'LIFESTYLE' },
      ],
      type: null,
      title: null,
      subtitle: null,
      body: null,
      imageUrl: null,
      buttonText: null,
      buttonLink: null,
      publishAt: null,
      unpublishAt: null,
      edit: {
        id: null,
        type: null,
        title: null,
        subtitle: null,
        body: null,
        imageUrl: null,
        buttonText: null,
        buttonLink: null,
        publishAt: null,
        unpublishAt: null,
      },
      editIsOpen: false,
      confirmDeleteIsOpen: false,
      deleteId: null,
      previewPost: null,
      previewPostIsOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      posts: 'fifth-tab-post/list',
    }),
    postsLoading() {
      return this.$store.state['fifth-tab-post'].isFindPending
    },
  },
  methods: {
    async create() {
      await this.$store.dispatch('fifth-tab-post/create', {
        title: this.title,
        subtitle: this.subtitle,
        type: this.type,
        body: this.body,
        imageUrl: this.imageUrl,
        buttonText: this.buttonText,
        buttonLink: this.buttonLink,
        publishAt: this.publishAt,
        unpublishAt: this.unpublishAt,
      })

      this.title = null
      this.subtitle = null
      this.body = null
      this.type = null
      this.imageUrl = null
      this.buttonLink = null
      this.buttonText = null
      this.publishAt = null
      this.unpublishAt = null
    },
    async remove(id) {
      await this.$store.dispatch('fifth-tab-post/remove', [id])
      this.confirmDeleteIsOpen = false
    },
    cancelRemove() {
      this.deleteId = null
      this.confirmDeleteIsOpen = false
    },
    openEdit(item) {
      this.edit = {
        id: item.id,
        title: item.title,
        subtitle: item.subtitle,
        type: item.type,
        body: item.body,
        imageUrl: item.imageUrl,
        buttonText: item.buttonText,
        buttonLink: item.buttonLink,
        publishAt: item.publishAt,
        unpublishAt: item.unpublishAt,
      }
      console.log(this.edit)
      this.edit.publishAt = DateTime.fromISO(item.publishAt).toFormat('yyyy-MM-dd')
      this.edit.unpublishAt = DateTime.fromISO(item.unpublishAt).toFormat('yyyy-MM-dd')
      this.editIsOpen = true
    },
    async update() {
      await this.$store.dispatch('fifth-tab-post/patch', [this.edit.id, this.edit])
      this.edit = {}
      this.editIsOpen = false
    },
    openRemoveDialog(id) {
      this.deleteId = id
      this.confirmDeleteIsOpen = true
    },
    openPreview(item) {
      this.previewPost = item
      this.previewPostIsOpen = true
    }
  },
  mounted() {
    this.$store.dispatch('fifth-tab-post/find', {
      query: {
        $limit: 50,
      }
    })
  }
}
</script>

<style lang="scss" scoped>
</style>