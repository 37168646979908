<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="1" class="pa-4 mb-6">
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="landingRecords">
              <template v-slot:item.createdAt="{ item }">
                {{item.createdAt}}
              </template>
            </v-data-table>
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AnalyticsLandingPage',

  components: {
  },

  data() {
    return {
      headers: [
        {
          text: 'Created',
          value: 'createdAt',
        },
        {
          text: 'Updated',
          value: 'updatedAt',
        },
        {
          text: 'Customer',
          value: 'customerName',
        },
        {
          text: 'Product',
          value: 'productId',
        },
        {
          text: 'Status',
          value: 'checkoutStep',
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      landingRecords: 'landing/list',
    }),
    langingItemsLoading() {
      return this.$store.state['landing'].isFindPending
    },
  },

  mounted() {
    this.$store.dispatch('landing/find', {
      query: {
        $limit: 500,
        $sort: {updatedAt: -1 },
      }
    })
  }
}
</script>

<style lang="scss">

</style>