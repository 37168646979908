<template>
  <div class="intro-offers-page">
    <v-main class="lighten-3">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-sheet elevation="1" class="pa-4 mb-6">
              <v-select
                outlined
                :value="selectedStudio"
                :items="studioOptions"
                hide-details
                return-value
                label="Select Studio"
                @change="setSelectedStudio"
              />
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-sheet elevation="1" class="pa-4 mb-6">
              <v-tabs grow>
                <v-tab :to="{ name: 'IntroOffers5DayRoute' }">5 Day</v-tab>
                <v-tab :to="{ name: 'IntroOffers15DayRoute' }">15 Day</v-tab>
                <v-tab :to="{ name: 'IntroOffers30DayRoute' }">30 Day</v-tab>
                <v-tab :to="{ name: 'IntroOffers90DayRoute' }">90 Day</v-tab>
                <v-tab :to="{ name: 'IntroOffers365DayRoute' }">1 Year</v-tab>
              </v-tabs>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-sheet elevation="1" class="pa-4 mb-6">
              <v-col cols="12" class="pt-5">
                <router-view></router-view>
              </v-col>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  name: 'TeacherClassConfirmPage',

  data() {
    return {
      studioOptions: [
        {
          text: 'Costa Mesa',
          value: 'Costa Mesa',
        },
        {
          text: 'Long Beach',
          value: 'Long Beach',
        },
        {
          text: 'Newport Beach',
          value: 'Newport Beach',
        },
      ],
    }
  },

  computed: {
    selectedStudio() {
      return this.$store.state.meta.introOfferSelectedStudio
    }
  },

  methods: {
    setSelectedStudio(val) {
      this.$store.commit('meta/setIntroOfferSelectedStudio', val)
    }
  }
}
</script>