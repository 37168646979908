<template>
  <v-form @submit.prevent="create">
    <v-row>
      <v-col cols="12">
        <h2>Workshop Credits</h2>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row align-content="end">
      <v-col cols="5">
        <v-select
          label="Mariana Credit Package"
          :items="marianaPackages"
          :item-text="packageItemText"
          return-object
          v-model="selectedMarianaCredit"
          :disabled="marianaPackagesFindLoading"
          :loading="marianaPackagesFindLoading">
        </v-select>
      </v-col>
      <v-col cols="5">
        <v-autocomplete
          v-model="marianaClassSession"
          :loading="marianaWorkshopsFindLoading"
          :items="marianaWorkshops"
          :search-input.sync="search"
          cache-items
          class="mx-4"
          hide-no-data
          hide-details
          :item-text="classSessionItemText"
          return-object
          label="Mariana Class Session"
        ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-btn
          color="primary"
          :disabled="!canSubmit"
          block
          depressed
          type="submit">
          Create
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Credit Package
                </th>
                <th class="text-left">
                  Workshop
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in marianaWorkshopCredits"
                :key="item.id"
              >
                <td>{{ item.marianaProductName }} (<a :href="`https://rayoga.marianatek.com/admin/products/credits/packages/edit/${item.marianaProductId}`">{{ item.marianaProductId }}</a>)</td>
                <td>{{ item.marianaClassSessionName }} (<a :href="`https://rayoga.marianatek.com/admin/schedule/edit/${item.marianaClassSessionId}`">{{ item.marianaClassSessionId }}</a>)</td>
                <td><v-btn icon depressed @click.prevent="remove(item.id)"><v-icon>mdi_delete</v-icon></v-btn></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'

export default {
  name: 'WorkshopCreditForm',
  data() {
    return {
      selectedMarianaCredit: null,
      marianaClassSession: null,
      search: null,
    }
  },
  computed: {
    ...mapGetters({
      marianaPackages: 'package/list',
      marianaWorkshops: 'class-session/list',
      marianaWorkshopCredits: 'workshop-credit/list',
    }),
    marianaPackagesFindLoading() {
      return this.$store.state['package'].isFindPending
    },
    marianaWorkshopsFindLoading() {
      return this.$store.state['class-session'].isFindPending
    },
    marianaWorkshopCreditCreatePending() {
      return this.$store.state['workshop-credit'].isCreatePending
    },
    canSubmit() {
      return this.selectedMarianaCredit !== null && this.marianaClassSession !== null
    }
  },
  watch: {
    search (val) {
      val && val !== this.select && this.getClassSessions(val)
    },
  },
  methods: {
    async create() {
      await this.$store.dispatch('workshop-credit/create', {
        marianaProductId: this.selectedMarianaCredit.id,
        marianaProductName: this.selectedMarianaCredit.attributes.title,
        marianaClassSessionId: this.marianaClassSession.id,
        marianaClassSessionName: this.marianaClassSession.attributes.class_type_display,
      })

      this.selectedMarianaCredit = null
      this.marianaClassSession = null
    },
    async remove(id) {
      await this.$store.dispatch('workshop-credit/remove', [id])
    },
    async getClassSessions(query) {
      this.$store.dispatch('class-session/find', {
        query: {
          limit: 50,
          query,
          min_datetime: DateTime.now().toFormat('yyyy-MM-dd'),
          class_tags: 4906,
        }
      })
    },
    packageItemText(item) {
      return `${item.attributes.title} (${item.id})`
    },
    classSessionItemText(item) {
      return `${item.attributes.class_type_display} (${item.id})`
    }
  },
  mounted() {
    this.$store.dispatch('package/find', {
      query: {
        limit: 100,
        is_active: true,
      }
    })
    //this.getClassSessions()
    this.$store.dispatch('workshop-credit/find', {
      query: {
        $limit: 50,
      }
    })
  }
}
</script>

<style lang="sass">
</style>