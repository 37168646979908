<template>
  <div class="table-container">
    <v-data-table
      :options="{ itemsPerPage: -1 }"
      dense
      sort-by="purchaseDate"
      :headers="headers"
      :items="filteredResults">
      <template v-slot:item.purchaseDate="{ item }">
        <span v-if="item">{{ purchaseFormattedDate(item.purchaseDate) }}</span>
      </template>
      <template v-slot:item.marianaCustomerName="{ item }">
        <a v-if="item" :href="`https://rayoga.marianatek.com/admin/user/profile/${item.marianaCustomerId}/payment-methods`" target="_blank">
          {{ item.marianaCustomerName }}
        </a>
      </template>
      <template v-slot:item.thirtyDayCurrentMembership="{ item }">
        <v-select
          v-if="item"
          class="pt-6"
          outlined
          dense
          clearable
          :value="item.thirtyDayCurrentMembership"
          :items="purchaseHistoryOptions"
          @change="updatePurchaseHistory($event, item.id)"
        />
      </template>
      <template v-slot:item.thirtyDayComments="{ item }">
        <v-btn
          v-if="item.thirtyDayComments === null || item.thirtyDayComments === ''"
          x-small
          depressed
          block
          color="primary"
          @click="openCommentDialog(item)"
          >
          Add Comments
        </v-btn>
        <v-btn
          v-if="item.thirtyDayComments !== null && item.thirtyDayComments.length > 0"
          x-small
          depressed
          block
          color="green"
          @click="openCommentDialog(item)"
          >
          View Comments
        </v-btn>
      </template>
      <template v-slot:item.thirtyDayCompleted="{ item }">
        <v-btn v-if="item" x-small color="success" depressed @click="submitItem($event, item.id)">
          Save
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      v-model="commentsDialog"
      v-if="commentsDialog"
      max-width="500"
    >
      <v-card class="pa-5">
        <v-card-title>
          Add Comments for {{ commentsDialogItem.marianaCustomerName }}
        </v-card-title>
        <v-textarea
          outlined
          v-model="tempFirstCallComments">
        </v-textarea>
        <v-btn
          depressed
          color="primary"
          @click="updateThirtyDayComments($event, commentsDialogItem.id)">
          Save
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'

export default {
  name: 'ThirtyDayTable',

  props: [
    'customers'
  ],

  data() {
    return {
      commentsDialog: false,
      commentsDialogItem: null,
      tempFirstCallComments: null,
      headers: [
        {
          text: 'Purchase Date',
          value: 'purchaseDate',
        },
        {
          text: 'Customer',
          value: 'marianaCustomerName',
        },
        {
          text: 'Current Membership/Package',
          value: 'thirtyDayCurrentMembership'
        },
        {
          text: 'Comments',
          value: 'thirtyDayComments'
        },
        {
          text: '',
          value: 'thirtyDayCompleted'
        }
      ],
      purchaseTypeOptions: [
      {
          text: 'In Studio',
          value: 'In Studio',
        },
        {
          text: 'Web (ECCO)',
          value: 'Web (ECCO)',
        },
        {
          text: 'Self Bought',
          value: 'Self Bought',
        },
      ],
      purchaseHistoryOptions: [
        {
          text: 'Essential Limited',
          value: 'Essential Limited',
        },
        {
          text: 'Essential Unlimited',
          value: 'Essential Unlimited',
        },
        {
          text: 'Plus Limited',
          value: 'Plus Limited',
        },
        {
          text: 'Plus Unlimited',
          value: 'Plus Unlimited',
        },
        {
          text: 'Premium Limited',
          value: 'Premium Limited',
        },
        {
          text: 'Premium Unlimited',
          value: 'Premium Unlimited',
        },
        {
          text: 'Groupon',
          value: 'Groupon',
        },
        {
          text: 'ClassPass',
          value: 'ClassPass',
        },
        {
          text: 'Class Package',
          value: 'Class Package',
        },
        {
          text: 'Other',
          value: 'Other',
        },
        {
          text: 'Nothing Yet',
          value: 'Nothing Yet',
        },
      ]
    }
  },

  computed: {
    ...mapGetters({
      introOfferCustomers: 'intro-offer-customer/list'
    }),

    selectedStudio() {
      return this.$store.state.meta.introOfferSelectedStudio
    },

    filteredResults() {
      let filtered = this.introOfferCustomers

      filtered = filtered.filter(o => o.purchaseLocation === this.selectedStudio)
      filtered = filtered.filter(o => o.purchaseDate <= DateTime.now().minus({ 'day': 30 }).toISO())
      filtered = filtered.filter(o => o.fiveDayCompleted === true)
      filtered = filtered.filter(o => o.fifteenDayCompleted === true)
      filtered = filtered.filter(o => o.thirtyDayCompleted === false)

      return filtered
    }
  },

  watch: {
    selectedStudio() {
      this.findIntroOfferCustomers()
    }
  },

  methods: {
    openCommentDialog(item) {
      this.commentsDialog = true
      this.commentsDialogItem = item
      this.tempFirstCallComments = item.thirtyDayComments
    },
    purchaseFormattedDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)
    },
    async findIntroOfferCustomers() {
      await this.$store.commit('intro-offer-customer/clearAll', this.$store.state['intro-offer-customer'])
      this.$store.dispatch('intro-offer-customer/find', {
        query: {
          $limit: 500,
          purchaseLocation: this.selectedStudio,
          purchaseDate: {
            $lte: DateTime.now().minus({ 'day': 30 })
          },
          fiveDayCompleted: true,
          fifteenDayCompleted: true,
          thirtyDayCompleted: {
            $ne: true,
          }
        }
      })
    },
    async updatePurchaseHistory(item, id) {
      this.$store.dispatch('intro-offer-customer/patch', [id, { thirtyDayCurrentMembership: item }])
    },
    async updateThirtyDayComments(item, id) {
      this.$store.dispatch('intro-offer-customer/patch', [id, { thirtyDayComments: this.tempFirstCallComments }])
      this.commentsDialog = false
    },
    async submitItem(item, id) {
      await this.$store.dispatch('intro-offer-customer/patch', [id, { thirtyDayCompleted: true }])
      this.findIntroOfferCustomers()
    }
  },

  mounted() {
    this.findIntroOfferCustomers()
  },

  destroyed() {
    this.$store.commit('intro-offer-customer/clearAll', this.$store.state['intro-offer-customer'])
  }
}
</script>