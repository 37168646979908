<template>
  <div class="autolayout-table">
    <!--<attendance-heatmap v-if="processedData !== null" :dataset="generateClassroomData('Light CM')" />-->
    <v-col cols="12" class="text-center" v-if="dataLoading">
      <heart-loader />
    </v-col>
    <v-data-table
      v-if="processedData && !dataLoading"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :loading="dataLoading"
      :headers="headers"
      :items="processedData"
      :items-per-page="500"
      :expanded.sync="expanded"
      :single-expand="true"
      :item-class="rowClasses"
      show-expand
      dense
      item-key="classArray[0].mariana_id">

      <template v-slot:item.updateable="{ item }">
        <v-icon small v-if="item.updateToPASPending">mdi-arrow-up-bold</v-icon>
        <v-icon small v-if="item.updateToFCFSPending">mdi-arrow-down-bold</v-icon>
      </template>

      <template v-slot:item.timeSlot="{ item }">
        {{item.dayOfWeek}} - {{item.startTime}}
      </template>

      <template v-slot:item.instructor_names="{ item }">
        {{getTeacherName(item.classArray)}}
      </template>

      <template v-slot:item.classArray="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ item.classArray.length }}
            </span>
          </template>
          <p class="mb-0" v-for="clas in item.classArray" :key="clas.mariana_id">{{clas.recurring_id}} | {{clas.start_datetime}}</p>
        </v-tooltip>
      </template>

      <template v-slot:item.capacityAvg="{ item }">
        <v-chip
          :color="getColor(item.capacityAvg)"
          dark
          small
        >
          {{ parseFloat(item.capacityAvg).toFixed(1) }}%
        </v-chip>
      </template>

      <template v-slot:item.threeweek="{ item }">
        <span v-if="item.classArrayDesc[3]">{{ item.classArrayDesc[3].standard_reservation_user_count }}</span>
        <span v-if="!item.classArrayDesc[3]">-</span>
      </template>

      <template v-slot:item.twoweek="{ item }">
        <span v-if="item.classArrayDesc[2]">{{ item.classArrayDesc[2].standard_reservation_user_count }}</span>
        <span v-if="!item.classArrayDesc[2]">-</span>
      </template>

      <template v-slot:item.oneweek="{ item }">
        <span v-if="item.classArrayDesc[1]">{{ item.classArrayDesc[1].standard_reservation_user_count }}</span>
        <span v-if="!item.classArrayDesc[1]">-</span>
      </template>

      <template v-slot:item.currentweek="{ item }">
        <span v-if="item.classArrayDesc[0]">{{ item.classArrayDesc[0].standard_reservation_user_count }}</span>
        <span v-if="!item.classArrayDesc[0]">-</span>
      </template>

      <template v-slot:item.layoutFormat="{ item }">
        <v-icon v-if="item.layoutFormat === 'pick-a-spot'">grid_on</v-icon>
        <v-icon v-if="item.layoutFormat === 'first-come-first-serve'">grid_off</v-icon>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="5">
              <v-list>
                <v-list-item v-for="clas in item.classArray" :key="clas.mariana_id">
                  <a :href="getSingleMarianaClassLink(clas)" target="_blank">{{formatDate(clas.start_datetime)}}</a> <v-chip>{{clas.instructor_names[0]}}</v-chip> <v-chip>{{clas.standard_reservation_user_count}}</v-chip>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="7">
              <v-sheet class="my-4" color="#2b2b2b">
                <attendance-line-chart :dataset="item.trendingValues" />
                <!--<v-sparkline
                  :auto-draw="expanded[0] === item.recurringId"
                  :value="item.trendingValues"
                  line-width="2"
                  color="rgba(255, 255, 255, .7)"
                  height="100"
                  padding="24"
                  stroke-linecap="round"
                  smooth
                >
                  <template v-slot:label="item">
                    {{ item.value }}
                  </template>
                </v-sparkline>-->
              </v-sheet>
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>

    
  </div>
</template>

<script>
import axios from 'axios'
import { orderBy, groupBy } from 'lodash'
import { DateTime } from 'luxon'
//import attendanceHeatmap from './attendanceHeatmap.vue'
import attendanceLineChart from './attendanceLineChart.vue'
import heartLoader from '../heartLoader.vue'
import HeartLoader from '../heartLoader.vue'

export default {
  components: {
    //attendanceHeatmap,
    attendanceLineChart,
    heartLoader,
    HeartLoader
},
  name: 'autolayoutTable',

  data() {
    return {
      processedData: null,
      dataLoading: false,
      sortBy: 'timeSlot',
      sortDesc: false,
      expanded: [],
      headers: [
        {
          text: '',
          value: 'updateable'
        },
        {
          text: 'Timeslot',
          align: 'start',
          value: 'timeSlot',
        },
        { text: 'Location', value: 'classroomName' },
        { text: 'Class', value: 'className' },
        { text: 'Instructor', value: 'instructor_names', sortable: false },
        { text: 'Attendance Avg', value: 'attendanceAvg' },
        { text: 'Utilization %', value: 'capacityAvg' },
        { text: 'Classes in group', value: 'classArray'},
        { text: '3WKA', value: 'threeweek', sortable: false },
        { text: '2WKA', value: 'twoweek', sortable: false },
        { text: '1WKA', value: 'oneweek', sortable: false },
        { text: 'CWK', value: 'currentweek', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
    }
  },

  methods: {
    rowClasses(item) {
      return (item.updateable && !item.updateToPASPending || !item.updateToFCFSPending) ? 'updateable' : 'red lighten-2'
    },
    async getProcessedData() {
      this.dataLoading = true
      const data = await axios.get('https://autolayout-microservice.herokuapp.com/process')
      this.processedData = orderBy(data.data, ['startDateTime', 'desc'])
      this.processedData = this.processedData.filter(o => o.attendanceAvg > 0)
      this.processedData = this.processedData.map(o => ({
        ...o,
        trendingValues: this.getTrendingClassAttendance(o),
        classArrayDesc: orderBy(o.classArray, ['start_datetime'], ['desc'])
      }))
      this.dataLoading = false
    },
    getColor (cap) {
      if (cap >= 80) return 'red'
      else if (cap >= 60) return 'orange'
      else return 'green'
    },
    getMarianaClassLink(item) {
      const recentClass = item.classArray[item.classArray.length - 1]
      const locationId = recentClass.relationships.location.data.id

      return `https://rayoga.marianatek.com/admin/location/${locationId}/class/${recentClass.mariana_id}`
    },
    getSingleMarianaClassLink(item) {
      const locationId = item.relationships.location.data.id
      return `https://rayoga.marianatek.com/admin/location/${locationId}/class/${item.mariana_id}`
    },
    getTrendingClassAttendance(item) {
      const values = item.classArray.map(o => {
        return {
          y: parseInt(o.standard_reservation_user_count),
          x: o.start_datetime,
        }
      })
      return values
    },
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL)
    },
    generateClassroomData(classroomName) {
      let classroomClasses = this.processedData.filter(o => o.classroomName === classroomName)
      classroomClasses = orderBy(classroomClasses, 'timeSlot')
      const classroomClassesByDay = groupBy(classroomClasses, 'dayOfWeek')
      const daysArray = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ]

      daysArray.reverse()

      const series = daysArray.map(o => {
        return {
          name: o,
          data: classroomClassesByDay[o].map((p) => {
            return {
              x: p.startTime,
              y: parseInt(p.capacityAvg)
            }
          })
        }
      })

      return series
    },
    getTeacherName(classArray) {
      //classArray = classArray.reverse()
      if (classArray[classArray.length - 1].relationships.substituted_instructor) {
        if (classArray[classArray.length - 2].relationships.substituted_instructor) {
          if (classArray[classArray.length - 3].relationships.substituted_instructor) {
            return 'N/A'
          } else {
            return classArray[classArray.length - 3].instructor_names[0]
          }
        } else {
          return classArray[classArray.length - 2].instructor_names[0]
        }
      } else {
        return classArray[classArray.length - 1].instructor_names[0]
      }
    }
  },

  async mounted() {
    await this.getProcessedData()
  }
}
</script>