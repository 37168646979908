<template>
  <v-col cols="12" class="classroom-thresholds">
    <v-row wrap>
      <v-col cols="12">
        <v-form>
          <v-row align-content-center>
            <v-col>
              <v-select
                label="Classroom"
                v-model="classroom"
                :items="classrooms"
                item-text="attributes.name"
                item-value="id"
                return-object
                @change="getClassroomLayouts" />
            </v-col>
            <v-col>
              <v-select
                label="FCFS Layout"
                :disabled="!layouts || layoutsFindLoading"
                v-model="fcfs_layout"
                :items="layouts"
                return-object
                item-text="attributes.name"
                item-value="id" />
            </v-col>
            <v-col>
              <v-text-field label="Attendance Threshold" v-model="threshold" hint="Once average attendance is at or above this number the layout will switch to PAS." type="number" />
            </v-col>
            <v-col>
              <v-select
                label="PAS Layout"
                :disabled="!layouts || layoutsFindLoading"
                v-model="pas_layout"
                :items="layouts"
                return-object
                item-text="attributes.name"
                item-value="id" />
            </v-col>
            <v-col>
              <v-btn color="primary" @click.prevent="createThreshold">+</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-progress-circular
          v-if="classroomThresholdsLoading"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-simple-table v-if="classroomThresholds !== null && !classroomThresholdsLoading">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Classroom
                </th>
                <th class="text-left">
                  Threshold
                </th>
                <th class="text-left">
                  FCFS Layout
                </th>
                <th class="text-left">
                  PAS Layout
                </th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in classroomThresholds"
                :key="item.classroom_id"
              >
                <td>{{ item.classroom_name }}</td>
                <td>{{ item.max_threshold }}</td>
                <td>{{ item.fcfs_layout_name }}</td>
                <td>{{ item.pas_layout_name }}</td>
                <td>
                  <v-btn icon @click="removeClassroomThreshold(item._id)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'classroomThresholdForm',

  data() {
    return {
      classroom: null,
      pas_layout: null,
      fcfs_layout: null,
      threshold: null,
      classroomThresholds: null,
      classroomThresholdsLoading: false,
    }
  },

  computed: {
    ...mapGetters({
      classrooms: 'classroom/list',
      layouts: 'layout/list',
    }),
    classroomsFindLoading() {
      return this.$store.state['classroom'].isFindPending
    },
    layoutsFindLoading() {
      return this.$store.state['layout'].isFindPending
    }
  },

  methods: {
    getClassroomLayouts() {
      this.pas_layout = null
      this.fcfs_layout = null
      this.$store.dispatch('layout/find', {
        query: {
          limit: 50,
          classroom: this.classroom.id,
          listed: true,
        }
      })
    },
    async createThreshold() {
      await axios.post('https://autolayout-microservice.herokuapp.com/classroom-threshold', {
        classroom_id: this.classroom.id,
        classroom_name: this.classroom.attributes.name,
        max_threshold: this.threshold,
        min_threshold: null,
        pas_layout_id: this.pas_layout.id,
        pas_layout_name: this.pas_layout.attributes.name,
        fcfs_layout_id: this.fcfs_layout.id,
        fcfs_layout_name: this.fcfs_layout.attributes.name,
      })

      this.classroom = null
      this.pas_layout = null
      this.fcfs_layout = null
      this.threshold = null

      await this.getClassroomThresholds()
    },
    async getClassroomThresholds() {
      this.classroomThresholdsLoading = true
      const thresholds = await axios.get('https://autolayout-microservice.herokuapp.com/classroom-threshold')
      this.classroomThresholds = thresholds?.data?.data
      this.classroomThresholdsLoading = false
    },
    async removeClassroomThreshold(id) {
      await axios.delete(`https://autolayout-microservice.herokuapp.com/classroom-threshold/${id}`)
      await this.getClassroomThresholds()
    }
  },

  async mounted() {
    this.$store.dispatch('classroom/find', {
      query: {
        limit: 50,
      }
    })

    await this.getClassroomThresholds()
  }
}
</script>

<style lang="scss">

</style>