var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"autolayout-table"},[(_vm.dataLoading)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('heart-loader')],1):_vm._e(),(_vm.processedData && !_vm.dataLoading)?_c('v-data-table',{attrs:{"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading":_vm.dataLoading,"headers":_vm.headers,"items":_vm.processedData,"items-per-page":500,"expanded":_vm.expanded,"single-expand":true,"item-class":_vm.rowClasses,"show-expand":"","dense":"","item-key":"classArray[0].mariana_id"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.updateable",fn:function(ref){
var item = ref.item;
return [(item.updateToPASPending)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-up-bold")]):_vm._e(),(item.updateToFCFSPending)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-down-bold")]):_vm._e()]}},{key:"item.timeSlot",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dayOfWeek)+" - "+_vm._s(item.startTime)+" ")]}},{key:"item.instructor_names",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTeacherName(item.classArray))+" ")]}},{key:"item.classArray",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.classArray.length)+" ")])]}}],null,true)},_vm._l((item.classArray),function(clas){return _c('p',{key:clas.mariana_id,staticClass:"mb-0"},[_vm._v(_vm._s(clas.recurring_id)+" | "+_vm._s(clas.start_datetime))])}),0)]}},{key:"item.capacityAvg",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.capacityAvg),"dark":"","small":""}},[_vm._v(" "+_vm._s(parseFloat(item.capacityAvg).toFixed(1))+"% ")])]}},{key:"item.threeweek",fn:function(ref){
var item = ref.item;
return [(item.classArrayDesc[3])?_c('span',[_vm._v(_vm._s(item.classArrayDesc[3].standard_reservation_user_count))]):_vm._e(),(!item.classArrayDesc[3])?_c('span',[_vm._v("-")]):_vm._e()]}},{key:"item.twoweek",fn:function(ref){
var item = ref.item;
return [(item.classArrayDesc[2])?_c('span',[_vm._v(_vm._s(item.classArrayDesc[2].standard_reservation_user_count))]):_vm._e(),(!item.classArrayDesc[2])?_c('span',[_vm._v("-")]):_vm._e()]}},{key:"item.oneweek",fn:function(ref){
var item = ref.item;
return [(item.classArrayDesc[1])?_c('span',[_vm._v(_vm._s(item.classArrayDesc[1].standard_reservation_user_count))]):_vm._e(),(!item.classArrayDesc[1])?_c('span',[_vm._v("-")]):_vm._e()]}},{key:"item.currentweek",fn:function(ref){
var item = ref.item;
return [(item.classArrayDesc[0])?_c('span',[_vm._v(_vm._s(item.classArrayDesc[0].standard_reservation_user_count))]):_vm._e(),(!item.classArrayDesc[0])?_c('span',[_vm._v("-")]):_vm._e()]}},{key:"item.layoutFormat",fn:function(ref){
var item = ref.item;
return [(item.layoutFormat === 'pick-a-spot')?_c('v-icon',[_vm._v("grid_on")]):_vm._e(),(item.layoutFormat === 'first-come-first-serve')?_c('v-icon',[_vm._v("grid_off")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-list',_vm._l((item.classArray),function(clas){return _c('v-list-item',{key:clas.mariana_id},[_c('a',{attrs:{"href":_vm.getSingleMarianaClassLink(clas),"target":"_blank"}},[_vm._v(_vm._s(_vm.formatDate(clas.start_datetime)))]),_vm._v(" "),_c('v-chip',[_vm._v(_vm._s(clas.instructor_names[0]))]),_vm._v(" "),_c('v-chip',[_vm._v(_vm._s(clas.standard_reservation_user_count))])],1)}),1)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-sheet',{staticClass:"my-4",attrs:{"color":"#2b2b2b"}},[_c('attendance-line-chart',{attrs:{"dataset":item.trendingValues}})],1)],1)],1)],1)]}}],null,false,2528078854)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }