<template>
  <v-app-bar
    app
    class="top-bar"
  >
    <v-container fluid class="py-0 fill-height">
      <v-responsive max-width="200">
        <h1 class="logo">ECCO</h1>
      </v-responsive>

      <!--<router-link
        :exact="link.exact"
        class="v-btn v-btn--flat v-btn--text theme--light v-size--default primary--text"
        :to="{ name: link.name }"
        v-for="link in links"
        :key="link.name">
        {{ link.text }}
      </router-link>-->

      <v-spacer></v-spacer>
      <router-link
        v-if="!isAuthenticated"
        class="v-btn v-btn--flat v-btn--text theme--light v-size--default primary--text"
        :to="{ name: 'LoginRoute' }">
        Login
      </router-link>
      <span v-if="!isAuthenticated">/</span>
      <router-link
        v-if="!isAuthenticated"
        class="v-btn v-btn--flat v-btn--text theme--light v-size--default primary--text"
        :to="{ name: 'SignupRoute' }">
        Sign Up
      </router-link>
      <session-user-menu v-if="isAuthenticated" />
    </v-container>
  </v-app-bar>
</template>

<script>
import SessionUserMenu from '@/components/user/sessionUserMenu'

export default {
  name: 'TopBar',
  components: {
    SessionUserMenu,
  },
  data: () => ({
    links: [
      {
        text: 'Dashboard',
        name: 'DashboardRoute',
        exact: true,
      },
    ],
  }),
  computed: {
    isAuthenticated() {
      return this.$store.state.auth.accessToken
    },
  }
}
</script>

<style lang="sass">
.logo
  font-size: 24px
  font-family: ethnocentric,sans-serif
  font-weight: 700
  font-style: normal
  color: #e2e2e3
</style>