<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="1" class="pa-4 mb-6">
          <v-col cols="12">
            <in-app-ad-form></in-app-ad-form>
          </v-col>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InAppAdForm from '../components/workflows/inAppAdForm.vue'
export default {
  name: 'InAppAdPage',

  components: {
    InAppAdForm
  }
}
</script>

<style lang="scss">

</style>