<template>
  <v-container>
    <v-row justify="center">
      <v-flex md3 class="text--center mt-5">
        <v-sheet class="py-5" elevation="1">
          <heart-loader />
          <h4 class="py-4">Logging you in...</h4>
          {{ authorizationCode }}
        </v-sheet>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import HeartLoader from '@/components/heartLoader'

export default {
  name: 'OAuthCallbackPage',
  components: {
    HeartLoader,
  },

  data() {
    return {
      authorizationCode: null,
      challenge: null,
      clientId: 'eQfycs5zGlkhajIXy6yZszZviLkK1da3YLIcQwQS',
    }
  },

  async mounted() {
    this.challenge = window.localStorage.getItem('challenge')
    this.authorizationCode = this.$route.query.code
    axios.post(`https://rayoga.marianatek.com/o/token/?client_id=${this.clientId}&code_verifier=${this.challenge}&code=${this.authorizationCode}&grant_type=authorization_code&redirect_uri=https://ecco-admin.rayoga.com/oauth/callback`)
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
  });
  }
}
</script>

<style lang="sass" scoped>
</style>