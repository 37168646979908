<template>
  <div class="attendance-linechart">
    <apexchart type="line" :options="chartOptions" :series="series" />
  </div>
</template>

<script>
export default {
  name: 'AttendanceLineChart',
  props: [
    'dataset'
  ],

  watch: {
    series(val) {
      this.series = val

    }
  },

  data() {
    return {
      ctx: null,
      chart: null,
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
        },
        dataLabels: {
          enabled: true
        },
        colors: ["#008FFB"],
        title: {
          text: 'Historical Attendance'
        },
      },
    }
  },

  mounted() {
    this.series = [{
      data: this.dataset,
    }]
  }
}
</script>

<style lang="scss">
.apexcharts-tooltip {
  color: #2b2b2b;
}
</style>