<template>
  <v-form @submit.prevent="create">
    <v-row>
      <v-col cols="12">
        <h2>In-app Ads</h2>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row align-content="end">
      <v-col cols="5">
        <v-text-field
          v-model="image_url"
          label="Image Url"
          hint="Image should be exactly 1200x628 pixels."
        />
      </v-col>
      <v-col cols="5">
        <v-text-field
          v-model="redirect_url"
          label="Redirect Url"
          hint="Make sure to include https://"
        />
      </v-col>
      <v-col cols="2">
        <v-btn
          color="primary"
          :disabled="!canSubmit"
          block
          depressed
          type="submit">
          Create
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Active</th>
                <th class="text-left">
                  Image
                </th>
                <th class="text-left">
                  Redirect
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in inAppAds"
                :key="item.id"
              >
                <td><v-simple-checkbox :value="item.active" @click.prevent="update(item)"></v-simple-checkbox></td>
                <td><v-responsive><img class="pt-3 pb-1" style="max-width: 500px" :src="item.image_url" /></v-responsive></td>
                <td><a :href="item.redirect_url">{{ item.redirect_url }}</a></td>
                <td><v-btn icon depressed @click.prevent="remove(item.id)"><v-icon>mdi_delete</v-icon></v-btn></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'InAppAdForm',
  data() {
    return {
      image_url: null,
      redirect_url: null,
    }
  },
  computed: {
    ...mapGetters({
      inAppAds: 'in-app-ad/list',
    }),
    inAppAdsLoading() {
      return this.$store.state['in-app-ad'].isFindPending
    },
    canSubmit() {
      return this.image_url !== null && this.redirect_url !== null
    }
  },
  methods: {
    async create() {
      await this.$store.dispatch('in-app-ad/create', {
        image_url: this.image_url,
        redirect_url: this.redirect_url,
        active: false,
        enableAt: Date.now()
      })

      this.image_url = null
      this.redirect_url = null
    },
    async update(item) {
      await this.$store.dispatch('in-app-ad/patch', [item.id, {
        active: !item.active,
      }, {}])
    },
    async remove(id) {
      await this.$store.dispatch('in-app-ad/remove', [id])
    },
  },
  mounted() {
    this.$store.dispatch('in-app-ad/find', {
      query: {
        $limit: 50,
      }
    })
  }
}
</script>

<style lang="sass">
</style>